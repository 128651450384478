enum MainPageTabs {
    summary = "summary",
    regions = "regions",
    sectors = "sectors",
    generations = "generations",
    investment = "investment",
}

enum ExploreStatsEnum {
    activeCompanies = "Active companies",

    maleLed = "Male-led companies",
    mixed = "Mixed board companies",
    unknown = "Unknown board companies",
    femaleLed = "Female-led companies",

    ethnicMaleLed = "Ethnic minority male-led companies",
    ethnicMixedLed = "Ethnic minority mixed-led companies",
    ethnicUnknownLed = "Ethnic minority unknown-led companies",
    ethnicFemaleLed = "Ethnic minority female-led companies",

    maleLedGrowth = "Male-led fast growth companies",
    mixedLedGrowth = "Mixed board fast growth companies",
    unknownLedGrowth = "Unknown board fast growth companies",
    femaleLedGrowth = "Female-led fast growth companies"
}

enum ContactTypeEnum {
    subscribe = "subscribe",
    contact = "contact",
}
export {
    MainPageTabs,
    ExploreStatsEnum,
    ContactTypeEnum,
};
