
import Modal from "react-modal";

interface ICustomModal {
    open: boolean;
    handleClose: any
    children: any;
    style?: Modal.Styles;
    className?: string | Modal.Classes | undefined;
}

function CustomModal({ open, children, handleClose, style, className }: ICustomModal) {
    // useEffect(() => {
    //     if (open) {
    //         document.body.style.overflow = "hidden";
    //     } else {
    //         document.body.style.overflow = "scroll";
    //     }
    // }, [open]);
    return (
        <Modal
            isOpen={open}
            onRequestClose={handleClose}
            shouldCloseOnOverlayClick
            shouldCloseOnEsc
            style={style}
            className={className}>
            {children}
        </Modal>
    );
}

export default CustomModal;
