import React from "react";

import { config } from "@fortawesome/fontawesome-svg-core";
import { AppProps } from "next/app";
import { ToastContainer } from "react-toastify";

import WelcomePopup from "@/features/homepage/WelcomePopup";
import { FiltersProvider } from "@/providers/FiltersProvider";
import CookieSettings from "@/shared/components/CookieSettings";
import ToastContainerCloseIconSvg from "@/shared/components/ToastContainerCloseIconSvg";
import ToastContainerIconSvg from "@/shared/components/ToastContainerIconSvg";

import "@fortawesome/fontawesome-svg-core/styles.css";
import "react-toastify/dist/ReactToastify.css";
import "react-tooltip/dist/react-tooltip.css";
import "react-modern-drawer/dist/index.css";
import "@/assets/styles/main.scss";

config.autoAddCss = false;
function App({ Component, pageProps }: AppProps) {

    return (
        <FiltersProvider>
            <Component {...pageProps} />
            <ToastContainer
                hideProgressBar
                position="bottom-left"
                icon={() => <ToastContainerIconSvg />}
                closeButton={() => <ToastContainerCloseIconSvg />}
            />
            <CookieSettings />
            <WelcomePopup />
        </FiltersProvider>
    );
}

export default App;
