import React, { useCallback, useEffect, useState } from "react";

import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useRouter } from "next/router";

import { APP_ROUTES } from "@/constants/routes";
import CustomModal from "@/shared/components/CustomModal";

function WelcomePopup() {
    const [isOpen, setIsOpen] = useState(false);
    const router = useRouter();

    useEffect(() => {
        if (!localStorage.getItem("welcomePopup") && router.pathname.includes("explore")) {
            localStorage.setItem("welcomePopup", "true");
            setIsOpen(true);
        }
    }, [router.pathname]);

    const handleClose = useCallback(() => {
        setIsOpen(false);
        localStorage.setItem("welcomePopup", "true");
    }, []);
    return (
        <CustomModal
            open={isOpen}
            handleClose={handleClose}
            className={"custom-modal-content"}>
            <div className={"commonModalWrapper"}>
                <div className="commonModal welcomeModal">
                    <div className="modalHeader">
                        <h5>Welcome to The Gender Index dashboard!</h5>
                        <FontAwesomeIcon icon={faXmark} onClick={handleClose} />
                    </div>

                    <div className="modalBody">
                        <span>
                            Welcome to The Gender Index – your platform for real-time insights into the UK company landscape.
                            Utilize our 12 powerful filters to explore and customize data, effortlessly revealing the impact of gender on UK companies.
                        </span>
                        <p>As of 2024, we&apos;ve introduced two new filters to enhance your experience: </p>
                        <ul>
                            <li>
                                <span><b>Importers and Exporters:&nbsp;</b>
                                    Uncover the dynamics of companies engaged in international trade, giving you a broader perspective on their economic activities.</span>
                            </li>
                            <li>
                                <span><b>Secured Debt:&nbsp;</b>
                                    Explore companies based on their secured debt status, providing valuable insights into their financial structures and stability.</span>
                            </li>
                        </ul>

                        <button
                            className="btn-red"
                            onClick={() => {
                                handleClose();
                                router.push(APP_ROUTES.explore);
                            }}>Explore the data</button>
                    </div>
                </div>
            </div >
        </CustomModal>
    );
}

export default WelcomePopup;
