/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState } from "react";

import { MainPageTabs } from "@/enum/common";

type FiltersContextType = {
    mainFilters: any;
    setMainFilters: any;
    tempFilters: any;
    setTempFilters: any;
    selectedFilterItem: any;
    setSelectedFilterItem: any;
    showDrawer: boolean;
    setShowDrawer: any;
    redirectFilters: any;
    setRedirectFilters: any;
    selectedTab: MainPageTabs | undefined;
    setSelectedTab: any;
    from: string | undefined;
    setFrom: any;
}

const FiltersContext = React.createContext<FiltersContextType>({
    mainFilters: null,
    selectedFilterItem: null,
    setMainFilters: () => { },
    setSelectedFilterItem: () => { },
    tempFilters: null,
    setTempFilters: () => { },
    showDrawer: false,
    setShowDrawer: () => { },
    redirectFilters: null,
    setRedirectFilters: () => { },
    selectedTab: MainPageTabs.regions,
    setSelectedTab: () => { },
    from: undefined,
    setFrom: () => { },
});

export interface FiltersProviderProps {
    children?: React.ReactNode;
}

function FiltersProvider({ children }: FiltersProviderProps) {
    const [mainFilters, setMainFilters] = useState(null);
    const [selectedFilterItem, setSelectedFilterItem] = useState(null);
    const [tempFilters, setTempFilters] = useState(null);
    const [showDrawer, setShowDrawer] = useState(false);
    const [redirectFilters, setRedirectFilters] = useState(null);
    const [selectedTab, setSelectedTab] = useState(MainPageTabs.regions);
    const [from, setFrom] = useState<string | undefined>();
    return (
        <FiltersContext.Provider
            value={{
                mainFilters,
                setMainFilters,
                tempFilters,
                setTempFilters,
                selectedFilterItem,
                setSelectedFilterItem,
                showDrawer,
                setShowDrawer,
                redirectFilters,
                setRedirectFilters,
                selectedTab,
                setSelectedTab,
                from,
                setFrom,
            }}>
            {children}
        </FiltersContext.Provider>
    );
};

const useFilters = () => React.useContext(FiltersContext);

export {
    useFilters,
    FiltersProvider,
};
