import * as React from "react";

function ToastContainerIconSvg(props: any) {
    return (

        <svg
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
            data-icon="check"
            data-prefix="fas"
            viewBox="0 0 512 512"
            {...props}
        >
            <path
                fill="#fff"
                d="M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7l233.4-233.3c12.5-12.5 32.8-12.5 45.3 0z"
            />
        </svg>
    );
};

export default ToastContainerIconSvg;
